<template>
  <div>
    <HomePageTop/>
    <div class="disclaimerPage">
      <div class="fontSize24 fontWeightBold color010101 margin-bottom-30"
           style="border-bottom:1px solid #DFDFDF;padding-bottom:30px ">
        ラクマート会員利用規約
      </div>
      <div class="line-height32 fontWeightBold color010101">
        RAKUMART 会員利用規約（以下、「本規約」といいます。）には、RAKUMART 株式会社（以下、「当社」とします。）によって運営する
        当サイト上で提供するサービ ス（以下「本サービス」とします。
        本サービスのご利用に於いて、会員の皆様に遵守して頂かなければならない事項、及 び当社と会員様との間の権利義務関係が定められており
        ます。本サービスを会員とし てご利用になられたい方は、本規約に同意の上、会員登録する前に、必ず全てを熟読 の上、会員登録にお進み頂
        けますようお願い申し上げます。
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">1. 本規約の適用</div>
        <div class="margin-bottom-34 line-height32">
          1-1 本規約は、本サービスにおける物品・役務サービスの購入者として利用に関する当社と会員との間の権利義務関係を定めることを目的と
          し、会員と当社間のサービ ス利用に関わる全ての関係に適用されます。
        </div>
        <div class=" line-height32">
          1-2 当社が当社のサイト上で掲載する本サービスに関する取り決め、諸規定についても、本規約の一部を構成するものとなります。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">2. 定義</div>
        <div class="margin-bottom-31 line-height32">
          <div>2-1 本サービスは、中国全土の工場、卸売市場、特定のショッピングサイトの商品を会員様に 代わって購入代行及び、それらに付随するサー
            ビスを意味します。
          </div>
          <div>（理由の如何を問わずサービス内容が変更された場合は、当該変更後のサービスを含 みます。）</div>
        </div>
        <div class="margin-bottom-31 line-height32">
          <div>2-2 本規約において、「当社サイト」とは、ドメインが、当社が運営する「rakumart.com」であり、当社が管理する全てのページを意味し
            ます。
          </div>
          <div>（理由の如何を問わず当社のサイトが変更された場合は、当該変更後のサイトを含む。）</div>
        </div>
        <div class="margin-bottom-32 line-height32">
          <div>2-3 本規約において、「会員」とは、本サービスにおける物品・役務サービスの購入者としての利用の登録がなされた事業者又は個人を意味
            します。
          </div>
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">3. 本規約遵守の同意について</div>
        <div class="line-height32">
          本規約は会員が本サービスを利用するにあたり遵守すべき事項を定めたものです。会員が本サービスに登録した時点で、本規約に同意したも
          のとさせて頂きます。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">4. 本規約の改定</div>
        <div class="line-height32">
          当社は、本規約を任意に改定出来るものとし、本規約の改定は当社サイトに掲示した時から効力を生じるものとなります。本規約の改定後、
          会員が本サービスを利用した場合、改定後の本規約に同意したものとさせて頂きます。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">5. 登録について</div>
        <div class="line-height32">
          5-1 会員は、本規約を遵守することに同意し、当社の定める特定の情報（以下、「登録事項」といいます。）を当社の定める方法で当社と共
          有することにより、当社に対 し、本サービスの利用登録を申請することが可能になります。
        </div>
        <div class="line-height32 margin-bottom-32">
          5-2 登録申請に於いては、原則として、本サービスを利用する個人又は事業者自身が行わなければならず、代理人による登録申請は無効とな
          ります。
        </div>
        <div class="line-height32 margin-bottom-32">
          5-3 当社は、当社の基準に沿って、会員の登録の可否を判断し、当社が登録を認める場合には、その事を会員に通知し、この通知により登録
          希望者が会員としての登録は完了したものとなります。
        </div>
        <div class="line-height32 margin-bottom-32">
          5-4 前項に定める登録の完了時に、本規約の諸規定に沿った、本サービスの利用契約が会員と当社との間に成立する事になります。
        </div>
        <div class="line-height32 margin-bottom-32">
          <div>5-5 当社は、５-１項に基づき登録を申請されたかたが、以下の各号のいずれかの事由に該当する場合は、当社が、５-１項に基づく申請を拒否
            することがあります。
          </div>
          <div>・未成年者及び反社会派組織に属すると判断した場合</div>
          <div>・本規約に違反する恐れがあると当社が判断した場合</div>
          <div>・当社に提供された登録事項の全部又は一部につき虚偽、誤記、記載漏れがあった場合</div>
          <div>・その他、当社が登録を適当でないと判断した場合</div>
        </div>
        <div>
          5-6 当社は、前項に該当すると判断した場合、その該当する項目や理由を会員に開示する義務を負いません。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">6. 登録内容の変更</div>
        <div class="line-height32">
          会員は、登録内容に変更があった場合は、遅滞する事なく、速やかに、当社の定める方法により、当該変更事項を当社に通知し、必要に応じ
          て、当社から要求された資料を提出するものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">7. 本サービスの停止、中断</div>
        <div class="line-height32 margin-bottom-32">
          <div>7-1 当社は、以下のどれかに該当する場合、会員に事前通知することなく、本サー
            ビスの利用の全部又は一部を停止又は中断することが出来るものとします。
          </div>
          <div>・本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合</div>
          <div>・火災、停電、戦争、天災地変などの不可抗力により本サービスの運営ができなくなった場合</div>
          <div>・その他、当社が停止又は中断を必要と判断した場合</div>
        </div>
        <div>7-2 当社は、前項に基づき当社が行った措置に基づき会員に生じた損害につき、一 切の責任を負いません。</div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">8. 権利帰属</div>
        <div class="line-height32">8-1 当社サイト及び本サービスに関する所有権及び知的財産権は全て当社に帰属するものとし、本規約に定める登録に基づく本サービスの利
          用許諾は、当社サイト又は本サービスに関する当社の知的財産権の使用許諾を意味するものではありません。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">9. 会員登録の抹消</div>
        <div class="line-height32 margin-bottom-32">
          <div>9-1 当社は、会員が、以下の各項目のどれかの事由に該当する場合は、事前に通知又は催告することなく、当該会員の登録を取り消すことが
            出来ます。
          </div>
          <div>・本規約のいずれかの項目に違反した場合</div>
          <div>・登録事項に虚偽の事実があることが判明した場合</div>
          <div>
            ・当社、本サービスの他の利用者又は第三者に損害を生じさせる恐れのある形で本サービスを利用した、又は、利用しようとした場合
          </div>
          <div>・手段の如何を問わず、本サービスの運営を妨害した場合</div>
          <div>・本人又は第三者による、直接的及び間接的（全てのインターネット上への書き込みを含む）に、当社又は従業員に対する暴言、脅迫的言動、
            法的責任を超えた不当な要求、風説の流布・偽計等による信用毀損・名誉毀損・業務妨害等があった場合
          </div>
          <div>・当社規定のサービス料金を当社の定める期日迄にお支払い頂け無い場合</div>
          <div>
            ・６ヶ月以上本サービスの利用がない場合や、当社からの連絡に対して応答（チャット・メール・電話のいずれか）がない場合
          </div>
          <div>・その他、当社が会員の登録の継続を適当では無いと判断した場合</div>
        </div>
        <div class="margin-bottom-32">9-2 会員は、当社の定める方法に従い、いつでも、自らの登録を取り消す事が出来ます。</div>
        <div class="margin-bottom-32">9-3 登録が取り消された場合、会員は、当社に対して負っている債務を、直ちに当社に対し、支払いを行わなければなりません。
        </div>
        <div class="margin-bottom-32">9-4 当社は、本項目及び前項に基づき当社が行った行為により会員に生じた損害について一切の責任を負いません。
        </div>
        <div>9-5 当社は、前項に該当すると判断した場合、その該当する項目や理由を会員に開示する義務を負いません。</div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">10. 本サービスの利用にあたって</div>
        <div class="margin-bottom-32">
          10-1 会員は、有効に登録されている期間内にのみ、本規約の目的の範囲内で、当社の定める方法に従い、本サービスを利用することが出来ま
          す。
        </div>
        <div class="margin-bottom-32">10-2 会員は、本サービスに於いて、出店者との間で契約が締結された場合、当社が当該出店者に対し、会員と共有した登録情報の中で、当社
          が任意に定める事項を開示することに同意するものとさせて頂きます。
        </div>
        <div class="line-height32 margin-bottom-32">
          <div>10-3 会員は、本サービスの利用に於いて、以下の各項目のどれかに該当する行為をしてはなりません。</div>
          <div>・当社や、本サービスの他の利用者又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為、
            又はそれらの情報を当社ウェブサイト又はインターネット上に送信する行為、犯罪行為に関連する行為
          </div>
          <div>・当社の本サービスに関わる、画像、イラスト、文章、動画、デザイン等、全ての範囲で複製、編集、転送、その他等の行為を無許可で行う
            こと
          </div>
          <div>・法令に違反する行為</div>
          <div>・公序良俗に反する行為</div>
          <div>
            ・出店者の判断に錯誤を印象付ける恐れのある行為、また誹謗・中傷・事実と異なる内容及び個人情報に関する情報を送信する行為
          </div>
          <div>
            ・コンピューター・ウィルスを含むファイル等、有害なコンピューター・プログラム等を当社サイトや関連サイトに送信する行為
          </div>
          <div>・本サービスに関連する、利用しうる情報を改ざんする行為</div>
          <div>・全く購入の意図が無いにも関わらず購入希望を出すこと、その他公正な取引慣行に反する行為</div>
          <div>・その他、当社が不適切と判断する行為</div>
        </div>
        <div>10-4 当社は、本サービスにおいて会員から当社サイトに対する情報の送信行為が各項目のいずれかに該当し、又は、該当する恐れがあると当
          社が判断した場合には、会員に事前に通知する事無く、当該情報の全部又は一部を削除する事が出来るものとさせて頂きます。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">11. 連絡及び通知について</div>
        <div>
          本サービスに関する問い合わせ、その他会員から当社に対する連絡、若しくは通知、又は、本規約の変更に関する通知、その他当社から会員
          に対する連絡、若しくは通知は、メール連絡や当社サイトへの掲示など、当社の定める方法で行うものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">12. 取り扱い出来ないもの</div>
        <div class="line-height32">
          <div>12-1 日本の法律によって輸入が禁止されているもの</div>
          <div>・麻薬、向精神薬、大麻、あへん、けしがら、覚せい剤、あへん吸煙具、指定薬物、爆発物、火薬類</div>
          <div>化学兵器の禁止及び特定物質の規制等に関する法律第 2 条第 3
            項に規定する特定物感染症の予防及び感染症の患者に対する医療に関する法律
          </div>
          <div>第6条第20項に規定する一種病原体等及び同条、第 21 項に規定する二種病原体等</div>
          <div>貨幣、紙幣、銀行券、印紙、郵便切手又は有価証券の偽造品、変造品、模造品及び偽造カード（生カードを含む）</div>
          <div>公安又は風俗を害すべき書籍、児童ポルノ、図画、彫刻物その他の物品</div>
          <div>特許権、実用新案権、意匠権、商標権、著作権、著作隣接権、回路配置利用権又は育成者権を侵害する物品</div>
          <div>（上記は税関 HP より抜粋）</div>
          <div>・中国政府が輸出を禁止している製品</div>
          <div>・ワシントン条約等に該当する製品</div>
          <div>・店舗の評価が著しく悪い店舗の商品や、その他当社が購入不可能だと判断された商品。</div>
          <div>・当社が社会の秩序を乱すと判断した商品</div>
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">13. 会員ＩＤ及びパスワードの管理</div>
        <div class="line-height32 margin-bottom-32">
          13-1 会員は、自己責任に於いて、ID 及びパスワードを各自で管理、保管し、これを公表、又は第三者に利用させたり、貸与、譲渡、名義変
          更、売買等をしてはならない ものとします。
        </div>
        <div class="line-height32 margin-bottom-32">
          13-2 パスワードの管理不充分、使用上の過誤、第三者の使用等による損害の責任は会員が負うものとし、当社は一切の責任を負いません。
        </div>
        <div class="line-height32 margin-bottom-32">
          13-3 当社は、会員ＩＤとパスワードの組み合わせが、当社と会員と共有した、登録情報と一致してログインした場合にのみ、その会員ＩＤを
          登録している会員自身による利用とみなします。
        </div>
        <div class="line-height32">
          13-4 会員は、パスワードが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその事を当社に通知し、当社からの指示に
          従うものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">14. クッキー等について</div>
        <div class="line-height32 margin-bottom-32">
          14-1 当社および当社のグループ会社（以下、総称して「当グループ」）は、会員がグループサイトにアクセスしたことを認証するため、会員
          のアクセス履歴や、利用状況の調査の為、更には、その他会員に最適なサービスを提供するために、会員が当グループのサーバにアクセスす
          る際のＩＰアドレスに関する情報、携帯電話端末でアクセスした場合には携帯端末の個体識別番号に関する情報、およびクッキー（cookie）
          技術を使用して会員のアクセス履歴等に関する情報を収集します。
        </div>
        <div class="line-height32">
          14-2 会員として本サービスを利用する為には、前項に承諾した上で、クッキーを受け付けることが条件となります。従いまして、ブラウザで
          クッキーを拒否する為の設定を行った段階で、本サービスの利用が出来無くなりますので予めご了承願います。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">15. 利用料金および支払方法</div>
        <div class="line-height32 margin-bottom-32">
          15-1 会員は本サービス利用の対価として、当社が別途定め、当サイトのご利用により発生した利用料金を当社が指定する方法により支払うも
          のとします。
        </div>
        <div class="line-height32">
          15-2 会員が利用料金の支払を許可なく遅滞した場合、会員サービスを通知なく中止とします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">16. キャンセル・返品・返金ポリシーについて
        </div>
        <div class="line-height32 margin-bottom-32">
          <div>16-1 キャンセルについて</div>
          <div>原則として、入金確認後のキャンセル・変更は出来ません。</div>
        </div>
        <div class="line-height32 margin-bottom-32">
          <div>16-2 返品について</div>
          <div> 中国内で不良品や店舗の発送ミスについて、中国の商習慣により、返送料を負担する事で返品が認められる場合があります。
            日本に到着後、不良品と認められた場合、その原因が当社のサービスの範囲内であれば、状態によって補償案を提示させて頂きます。当社に
            よる原因で無い場合は、購入店舗と交渉させて頂きます。その場合、返品交渉に成功した場合でも、通常、売買契約は中国内に
            よるものです
            ので、国際送料正規運賃、関税、その他の経費は会員の負担となります。
            対応期限は、自宅発送の場合、受取日より10日以内とし、Amazon、楽天倉庫、ヤフーショッピング等への発送の場合、受取日より30日以内とさせて頂きます。対応期限が過ぎて到着したものは返品契約無効となりますので、返金されません。
            それらの損害についても当社は責任を負いません。
          </div>
        </div>
        <div class="line-height32">
          <div>16-3 返金について</div>
          <div class="line-height32">
            <div>
              商品の返品等がありましても、買い付けを伴う当社の手数料返還は出来ません。不良品の返品や見積り差額が生じた場合、最終出荷精算後、返金要望があれば、２営業日以内にご指定口座に返金させて頂きます。
            </div>
            <div>
              会員が残金の返金を求める場合の振込手数料は会員の負担とします。
            </div>
            <div>
              当社都合で返金する場合の振込手数料は、当社が負担するものとします。
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">17. 保証の否認について</div>
        <div class="line-height32">
          <div>
            17-1 本サービスは、物品を購入したい事業者に対して物品を販売したい事業者を紹介する場を提供し、会員に代わって買い付けを代行する役
            務を提供するものであり、商品の品質、適法性などに関しては、責任を負いかねます。
          </div>
          <div>
            また、何らかの約束、その他の情報を得られることを保証するものではありません。
          </div>
          <div>
            販売店舗及び販売店舗等が販売する商品等の選定については会員の責任において行うものとし、当社は売買契約の当事者とはなりません。
          </div>
          <div>
            従って、商品等の瑕疵、不具合、数量不足、未着、詐欺、輸送事故等による全ての問題について責任を負いません。
          </div>
          <div>
            また、当社は、本サービスに関連し、出店者が実在していること、権利能力及び行為能力を有していること、販売許可の所持の有無について、
            何ら保証するものではありません（これらの事項の調査は会員の自己責任とします。）。さらに、会員が当社か
          </div>
          <div>
            ら直接又は間接に本サービスに関する情報を得た場合であっても、当社は会員に対し本規約において規定されている内容を超えてあらゆる保
            証でも行う事はありません。
          </div>
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">18. 免責事項</div>
        <div class="line-height32 margin-bottom-32">
          <div>
            18-1
          </div>
          <div>
            ・商品が、非正規品、コピー品、模倣品であった場合。
          </div>
          <div>
            ・輸送中に商品が破損した場合の保証は担当配送会社の規約による保証範囲となります。
          </div>
          <div>
            ・会員様より申告されました情報等に誤りがあった場合
          </div>
          <div>
            ・地震・洪水・津波・などの天災により商品が届けられなくなった場合
          </div>
          <div>
            ・戦争・クーデター・暴動・労働争議疫病、などにより商品が届けられなくなった場合
          </div>
          <div>
            ・商品が、中国税関や、日本税関で返却、没収、焼却処分になった場合
          </div>
          <div>
            ・届いた商品が、イメージ違いやお客様の都合で不要となった場合
          </div>
          <div>
            ・中国特有の運送事情、通関事情等により納期が遅れることがありますが、それに起因した損害等についても責任を負い兼ねます。
          </div>
        </div>
        <div class="line-height32 margin-bottom-32">
          18-2 届いた商品不良があった場合は、返品・交換の交渉は致しますが、販売店舗が、必ず対応される訳ではありません。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-3 対応の遅延、通知の遅延により生じた販売機会損失や価格・為替変動等による損害については責任を負わないものとします。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-4 当社の管理以外や予測不能な原因によるトラブルや、そのとラブルにより発生したシステムの中断、停止、データ消失、及び不正アクセ
          スなどにより生じた損害、及び当社のサービス利用に関する損害について責任を負わないものとします。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-5 当社は、ユーザーが本サイトを利用する際に、コンピュータウイルスなど有害なプログラム等による損害を受けないことを保証するもの
          ではありません。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-6 当社の管理以外の天災など予測不能な原因による、政治変動等、不可抗力と認められる理由により、本取引の継続、金銭の授受または取
          引の継続等が遅延、又は不能となった場合により生じた損害については責任を負わないものとします。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-7 当社は本サービスに関連して会員が被った損害、損失、費用（本サービスを通じた物品の購入に伴う事故、犯罪行為、契約の取消等に基
          づく損害等を含みますがこれらに限定されません。）、並びに、本サービスの提供の中断、停止、利用不能、変更及び当社による本規約に基
          づく会員の情報の削除、会員の登録の取消し等に関連して会員が被った損害、損失、費用に於いて、当社は賠償又は補償する責任を一切負わ
          ないものとします。尚、前項及び本項における「損害、損失、費用」には、直接的損害及び通常損害のみならず、逸失利益、事業機会の喪失、
          データの喪失、事業の中断、その他間接的、特別的、派生的若しくは付随的損害、弁護士費用等の全てを意味します。
        </div>
        <div class="line-height32 margin-bottom-32">
          18-8 海外商品の仕入発送代行の為、クーリングオフ及び PL 法につきましては適用外となります。
        </div>
        <div class="line-height32">
          18-9 第三者によって、当社サイトから他社サイトへのリンクが提供されている場合がありますが、当社は、当社サイト以外のウェブサイト及
          びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">19. 紛争処理及び損害賠償</div>
        <div class="line-height32 margin-bottom-32">
          19-1 会員は、本規約に違反することにより、又は、本サービスの利用に関連して当社に損害を与えた場合、当社に対し、その損害を速やかに
          賠償しなければなりません。
        </div>
        <div class="line-height32">
          19-2 会員による本サービスの利用に関連して、当社が、第三者から権利侵害その他の理由により何らかの請求を受けた場合は、その会員が、
          当社又は第三者に支払を余儀なくされた金額を賠償しなければなりません。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">20. プライバシー保護について</div>
        <div class="line-height32">
          当社は、会員の個人情報を別途定める「プライバシーポリシー」に従い取り扱うものとし、会員は、このことに同意するものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">21. 本規約の譲渡等</div>
        <div class="line-height32">
          21-1 会員は、本規約に基づく利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、転売、
          その他の処理をすることはできません。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">22. 紛争の処理について （準拠法及び管轄裁判所）
        </div>
        <div class="line-height32">
          本規約の準拠法は中国法とし、本規約に起因し又は関連する一切の紛争についての裁判所は、浙江省義烏人民法院を第一審の専属的合意管轄
          裁判所とします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="fontSize18 color010101 fontWeightBold margin-bottom-32">23. 協議解決</div>
        <div class="line-height32">
          当社及び会員は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上、速やかに解
          決を図るものとします。
        </div>
      </div>
      <div class="margin-top-50">
        <div class="color010101 margin-bottom-20">以上</div>
        <div class="flexAndCenter" style="justify-content: space-between">
          <div>2018 年 01 月 01 日 制定</div>
          <div style="text-decoration: underline;color: #B4272B;">最終更新日: 2022.1.26</div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    Foot
  }
}
</script>

<style lang="scss" scoped>
.disclaimerPage {
  width: 1400px;
  margin: 0 auto 30px;
  padding: 32px;
  background-color: #fff;
  border-bottom-left-radius: 10px;

  .line-height32 {
    line-height: 32px;
  }

  .margin-top-32 {
    margin-top: 32px
  }

  .margin-bottom-34 {
    margin-bottom: 34px
  }

  .margin-top-31 {
    margin-top: 31px
  }

  .margin-top-30 {
    margin-top: 30px
  }

  .margin-top-50 {
    margin-top: 50px
  }

  .margin-top-10 {
    margin-top: 10px
  }

  .margin-bottom-32 {
    margin-bottom: 32px
  }

  .margin-bottom-31 {
    margin-bottom: 31px
  }

  .margin-bottom-30 {
    margin-bottom: 30px
  }
}
</style>
